.font (@size) {
	font-size: @size;
	line-height: @size;
}

.font (@size, @height) {
	font-size: @size;
	line-height: @height;
}

.font (@size, @height, @weight) {
	font-size: @size;
	line-height: @height;
	font-weight: @weight;
}

.font (@size, @height, @weight, @family) {
	font: @weight @size/@height @family;
}

.font (@style, @size, @height, @weight, @family) {
	font: @style @weight @size/@height @family;
}

.display (@type) {
	display: @type;
}

.display (@type) when (@type = inline-block) {
	display: @type;
	vertical-align: top;
}

.size (@width, @height: auto) {
	width: @width;
	height: @height;
}

.max-size (@width, @height: initial) {
	max-width: @width;
	max-height: @height;
}

.min-size (@width, @height: initial) {
	min-width: @width;
	min-height: @height;
}

.position (@type, @top: auto, @right: auto, @bottom: auto, @left: auto) {
	position: @type;

	top: @top;
	right: @right;
	bottom: @bottom;
	left: @left;
}

.position-tl (@type, @top, @left) {
	position: @type;

	top: @top;
	right: auto;
	bottom: auto;
	left: @left;
}