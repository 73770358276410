@import "https://fonts.googleapis.com/css?family=Cuprum:400,700";

html, body, body div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, img, ins, kbd, q, samp, small, strong, sub, sup, var, b, i, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, figure, footer, header, hgroup, menu, nav, section, time, mark, audio, video {
  vertical-align: baseline;
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-size: 100%;
}

article, aside, figure, footer, header, hgroup, nav, section {
  display: block;
}

header, section, footer {
  clear: both;
}

html {
  overflow-y: scroll;
}

ul {
  list-style: outside disc;
  margin: 15px 0 15px 30px;
}

ol {
  list-style: outside decimal;
  margin: 15px 0 15px 30px;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, td img {
  vertical-align: top;
}

button, input, select, textarea {
  outline: none;
  margin: 0;
}

input::-moz-focus-inner, button::-moz-focus-inner {
  border: none;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

input[type="radio"], input[type="checkbox"] {
  margin: 1px 4px 4px 0;
}

input[type="text"], input[type="password"] {
  -webkit-appearance: none;
  border-radius: 0;
}

textarea {
  resize: vertical;
  -webkit-appearance: none;
  border-radius: 0;
  overflow: auto;
}

button, input[type="submit"] {
  width: auto;
  overflow: visible;
}

.ie7 input[type="submit"], .ie7 input[type="reset"], .ie7 input[type="button"] {
  filter: chroma(color= #000);
}

input[type="button"], input[type="submit"], button {
  cursor: pointer;
  -webkit-appearance: none;
  border-radius: 0;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  cursor: help;
  border-bottom: 1px dotted #000;
}

small {
  font-size: 85%;
}

strong {
  font-weight: bold;
}

sub, sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

hr {
  height: 1px;
  border: none;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  display: block;
}

pre, code, kbd, samp {
  font-family: monospace, sans-serif;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  white-space: pre-line;
  word-wrap: break-word;
}

html {
  min-width: auto;
  width: auto;
  height: 100%;
}

body {
  color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  min-width: 320px;
  min-height: initial;
  background: #111214;
  font: 14px / 20px Cuprum, arial, sans-serif;
  position: relative;
  inset: auto;
}

body input, body textarea, body button, body select, body option {
  font-family: Cuprum, arial, sans-serif;
}

body *, body :before, body :after {
  box-sizing: border-box;
}

.btn {
  text-align: center;
  color: #fff;
  background: #ffffff08;
  border-radius: 42px;
  margin: 10px;
  padding: 12px 22px;
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  transition: all .15s;
  position: relative;
  inset: auto;
}

.btn:hover {
  background: #ffffff12;
}

.btn-group {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.page {
  flex-direction: column;
  justify-content: center;
  display: flex;
  position: fixed;
  inset: 0;
}

.bio {
  flex-direction: column;
  align-items: center;
  padding: 0 0 34px;
  display: flex;
}

.bio--photo {
  width: 100px;
  height: 100px;
  background: url(photo.2163bd00.jpg) center / 100% 100%;
  border-radius: 20px;
  margin: 0 0 24px;
  position: relative;
  inset: auto;
}

.bio--name {
  font-size: 36px;
  line-height: 44px;
}

.bio--position {
  color: #ffffff80;
  margin: 6px 0 0;
  font-size: 18px;
  line-height: 20px;
  display: block;
}

.bio--position a {
  color: inherit;
  font-weight: 700;
  text-decoration: none;
}

.bio--position a:hover {
  text-decoration: underline;
}

/*# sourceMappingURL=index.1267e18a.css.map */
