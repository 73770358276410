// - Includes
@import "_mixins.less";
@import "_reset.less";


// - Fonts
@import (css) url(https://fonts.googleapis.com/css?family=Cuprum:400,700);


// - Colors
@background-primary: #111214;
@background-secondary: rgba(255, 255, 255, 0.03);
@background-secondary-hover: rgba(255, 255, 255, 0.07);

@foreground-primary: #FFFFFF;
@foreground-secondary: rgba(255, 255, 255, 0.5);


// - Breakpoints
@screen-xs-max: 767px;

@screen-sm-min: 768px;

@xs-only: ~"(max-width: @{screen-xs-max})";
@sm-only: ~"(min-width: @{screen-sm-min})";


// - Global
html {
  min-width: auto;
  .size (auto, 100%);
}

body {
  font: normal normal 14px/20px 'Cuprum', arial, sans-serif;
  background: @background-primary;
  color: @foreground-primary;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;

  .min-size (320px);
  .position (relative);

  input,
  textarea,
  button,
  select,
  option {
    font-family: 'Cuprum', arial, sans-serif;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
}

// - Components
.btn {
  padding: 12px 22px;
  text-align: center;
  background: @background-secondary;
  border-radius: 42px;
  color: @foreground-primary;
  text-decoration: none;
  transition: 150ms all ease;
  margin: 10px;

  .position (relative);
  .font (18px, 20px, 400);

  &:hover {
    background: @background-secondary-hover;
  }
}

.btn-group {
  justify-content: center;
  flex-wrap: wrap;

  .display (flex);
}


// - Blocks
.page {
  flex-direction: column;
  justify-content: center;

  .display (flex);
  .position (fixed, 0, 0, 0, 0);
}

.bio {
  padding: 0 0 34px;
  flex-direction: column;
  align-items: center;

  .display (flex);

  &--photo {
    background: url(../assets/photo.jpg);
    background-size: 100% 100%;
    background-position: center center;
    border-radius: 20px;
    margin: 0 0 24px;

    .size (100px, 100px);
    .position (relative);
  }

  &--name {
    .font (36px, 44px);
  }

  &--position {
    color: @foreground-secondary;
    margin: 6px 0 0;

    .display (block);
    .font (18px, 20px);

    a {
      color: inherit;
      font-weight: 700;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}